@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

@layer base {
  * {
    box-sizing: content-box;
  }

  h1 {
    @apply my-5;
    @apply text-4xl;
    @apply border-b-2;
  }
  h2 {
    @apply text-2xl;
    @apply my-5;

    &:before {
      content: "# ";
      font-weight: bold;
    }
  }

  p a,
  ul a,
  .warn a {
    @apply underline;
  }
}

.custom-form {
  display: flex;
  flex-direction: column;
  //@apply md:min-w-3xl;
  //min-width: 500px;
  //max-width: 80vw;
  margin: 20px;

  label {
    display: flex;

    > span {
      font-size: 20px;
      position: relative;
      text-transform: capitalize;
      transition: color 500ms ease-in-out, left 200ms ease-out;
      color: #030918;
      left: 0;

      &.reverse-animation {
        left: 5px;
      }
    }

    &:hover > span {
      color: #387be5;

      &:not(.reverse-animation) {
        left: -9px;
      }

      &.reverse-animation {
        left: 14px;
      }
    }
  }

  select,
  input[type="text"],
  input[type="password"],
  input[type="email"] {
    transition: border-bottom-color 100ms ease-in-out,
      border-left-color 300ms ease-in-out, border-top-color 200ms ease-in-out,
      border-right-color 400ms ease-in-out;
    border: 1px solid #898989;
    border-bottom-color: rgba(0, 0, 0, 0);
    border-left-color: rgba(0, 0, 0, 0);
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    margin: 10px 0;
    @apply px-4;
    @apply py-3;

    &:hover {
      border: #387be5 solid 1px;
      //border-top-color: rgba(0, 0, 0, 0.36);
      //border-right-color: rgba(0, 0, 0, 0.36);
    }
  }

  .button-group {
    display: flex;
    justify-content: center;

    .button {
      text-align: center;
      padding: 12px 20px;
      //background-color: #030918;

      &:last-child {
        flex-grow: 1;
      }

      &:hover {
        //background-color: rgb(45, 55, 72);

        &:last-child {
          background-color: #387be5;
        }
      }
    }
  }
}
